

// Bootstrap colors
$body-bg: #EEF2F9;
$body-color: #333;

// Layout colors
$card-bg: #fff;
$card-header-bg: #fff;
$border-color: #EBEBEB;

// Custom Colors
$carb-color: #eb5350;
$protein-color: #3b77b1;
$fat-color: #ec9e31;
$calorie-color: #0d9b0d;
$accent-color: #2260C4;

// Spacing, borders, etc.
$rounded-md: 10px;
$padding-md: 20px;
$padding-sm: 10px;


$gray: #a2a2a3;
$light-gray: #ebebeb;

$container-width: 1020px;
